import i18next from 'i18next';
import {useLocalization} from 'packages/localization/localization';
import {languages} from 'packages/localization/languages';
import {changeLanguage} from 'packages/localization/localization';
import {GetPath, OpenPage, history} from 'packages/history/history';
// components
import Logo from '../logo/logo.react';
import Dropdown from 'packages/dropdowns/dropdownBase.react';
import LakeGarda from './components/lakeGarda.react';
// icons
import {ReactComponent as LanguageIcon} from 'src/icons/language.svg';
import {ReactComponent as FacebookIcon} from 'src/icons/facebook.svg';
import {ReactComponent as InstagramIcon} from 'src/icons/instagram.svg';
import {ReactComponent as YoutubeIcon} from 'src/icons/youtube.svg';
// styles
import {css, CreateSheet} from 'aphrodite';
import {
    Accent1,
    ElementBg,
    Gray,
    PageBg,
    Primary,
    Secondary,
    StrokeColor,
    TextArea,
    TextColor,
} from 'src/themes/colors';
import {useLocation} from 'react-router-dom';

export default function Footer({showLake}: {showLake?: boolean}) {
    const {t, tReady} = useLocalization('footer');

    if (!tReady) {
        return null;
    }

    return (
        <div className={css(Styles.footer)}>
            <div className={css(Styles.container)}>
                <div className={css(Styles.top)} />
                <div className={css(Styles.row)}>
                    <div className={css(Styles.col, Styles.left)}>
                        <Logo
                            {...{
                                styles: {
                                    logo: Styles.logo,
                                },
                            }}
                        />
                        <LanguagesMenu />
                    </div>
                    <div className={css(Styles.col)}>
                        <Menu />
                    </div>
                    <div className={css(Styles.col, Styles.right)}>
                        <p className={css(Styles.contact)}>
                            <a
                                href="tel:+393429287530"
                                className={css(Styles.phone)}
                            >
                                +39 342 928 75 30
                            </a>
                        </p>
                        <p className={css(Styles.contact)}>
                            <a
                                href="tel:hello@motoragazzi.com"
                                className={css(Styles.contactLink)}
                            >
                                hello@motoragazzi.com
                            </a>
                        </p>
                        <ul className={css(Styles.social)}>
                            <li className={css(Styles.socialItem)}>
                                <a
                                    {...{
                                        className: css(Styles.socialItemLink),
                                        href: 'https://www.youtube.com/channel/UCjNrhJZQP931J4e8SxapTyw',
                                        target: '_blank',
                                        rel: 'noopener noreferrer',
                                        'aria-label': 'Youtube',
                                    }}
                                >
                                    <YoutubeIcon
                                        className={css(Styles.socialItemIcon)}
                                        width="30"
                                        height="30"
                                    />
                                </a>
                            </li>
                            <li className={css(Styles.socialItem)}>
                                <a
                                    {...{
                                        className: css(Styles.socialItemLink),
                                        href: 'https://www.instagram.com/motoragazzi/',
                                        target: '_blank',
                                        rel: 'noopener noreferrer',
                                        'aria-label': '@motoragazzi',
                                    }}
                                >
                                    <InstagramIcon
                                        className={css(Styles.socialItemIcon)}
                                        width="30"
                                        height="30"
                                    />
                                </a>
                            </li>
                            <li className={css(Styles.socialItem)}>
                                <a
                                    {...{
                                        className: css(Styles.socialItemLink),
                                        href: 'https://www.facebook.com/motoragazzi',
                                        target: '_blank',
                                        rel: 'noopener noreferrer',
                                        'aria-label': 'Facebook',
                                    }}
                                >
                                    <FacebookIcon
                                        className={css(Styles.socialItemIcon)}
                                        width="30"
                                        height="30"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {showLake ? (
                <div className={css(Styles.container)}>
                    <LakeGarda />
                </div>
            ) : null}
            <div className={css(Styles.container)}>
                <div className={css(Styles.row, Styles.bottom)}>
                    <ul className={css(Styles.bottomMenu)}>
                        <li className={css(Styles.bottomMenuItem)}>
                            <a
                                {...{
                                    className: css(Styles.bottomMenuItemLink),
                                    href: '/files/public/legal_notice.pdf',
                                    rel: 'noopener noreferrer',
                                    target: '_blank',
                                }}
                            >
                                {t('links.legal')}
                            </a>
                        </li>
                        <li className={css(Styles.bottomMenuItem)}>
                            <a
                                {...{
                                    className: css(Styles.bottomMenuItemLink),
                                    href: '/files/public/privacy_policy.pdf',
                                    rel: 'noopener noreferrer',
                                    target: '_blank',
                                }}
                            >
                                {t('links.privacy')}
                            </a>
                        </li>
                        <li className={css(Styles.bottomMenuItem)}>
                            <a
                                {...{
                                    className: css(Styles.bottomMenuItemLink),
                                    href: '/files/public/terms_and_conditions.pdf',
                                    rel: 'noopener noreferrer',
                                    target: '_blank',
                                }}
                            >
                                {t('links.terms')}
                            </a>
                        </li>
                    </ul>
                    <div className={css(Styles.copyright)}>© {new Date().getFullYear()} MotoRagazzi</div>
                </div>
            </div>
        </div>
    );
}

const LanguagesMenu = () => {
    const location = useLocation();
    const change = (code: string) => {
        const [, , ...restPathParts] = location.pathname.split('/');

        const newPath = `/${code}/${restPathParts.join('/')}`;

        history.push(newPath);
        changeLanguage(code);
    };
    return (
        <Dropdown
            {...{
                animation: 'fade',
                // FIXME: refactor i18next.language
                header: () => (
                    <div className={css(Styles.languageItem)}>
                        <LanguageIcon
                            className={css(Styles.languageIcon)}
                            width="24"
                            height="24"
                        />
                        <span className={css(Styles.lngTitle)}>{i18next.language}</span>
                    </div>
                ),
                dropElement: () => (
                    <ul className={css(Styles.dropdownActions)}>
                        {languages.map(lan => {
                            if (!lan.enabled || !lan.inMenu) return null;
                            return (
                                <li
                                    {...{
                                        key: lan.code,
                                        className: css(Styles.dropdownAction),
                                        onClick: () => change(lan.code),
                                    }}
                                >
                                    {lan.name}
                                </li>
                            );
                        })}
                    </ul>
                ),
            }}
        />
    );
};

const Menu = () => {
    const {t, tReady} = useLocalization('footer');

    if (!tReady) {
        return null;
    }

    return (
        <ul className={css(Styles.nav)}>
            <li className={css(Styles.navItem)}>
                <a
                    href={GetPath('fleet')}
                    className={css(Styles.navItemLink)}
                    onClick={OpenPage('fleet')}
                >
                    {t('links.fleet')}
                </a>
            </li>
            <li className={css(Styles.navItem)}>
                <a
                    href={GetPath('faq')}
                    className={css(Styles.navItemLink)}
                    onClick={OpenPage('faq')}
                >
                    {t('links.faq')}
                </a>
            </li>
            <li className={css(Styles.navItem)}>
                <a
                    href={GetPath('franchising')}
                    className={css(Styles.navItemLink)}
                    onClick={OpenPage('franchising')}
                >
                    {t('links.franchising')}
                </a>
            </li>
            <li className={css(Styles.navItem)}>
                <a
                    href={GetPath('contacts')}
                    className={css(Styles.navItemLink)}
                    onClick={OpenPage('contacts')}
                >
                    {t('links.contacts')}
                </a>
            </li>
        </ul>
    );
};

const Styles = CreateSheet({
    footer: {
        padding: '0 0 50px 0',
        backgroundColor: PageBg,
        transition: 'background-color 0.25s ease-in 0s, box-shadow 0.25s ease-in 0s',
        zIndex: 5,
    },
    top: {
        marginBottom: 50,
        borderTop: `1px solid ${StrokeColor}`,
    },
    container: {
        width: 1290,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0 45px',
        '@media(max-width: 900px)': {
            padding: '0 20px',
        },
    },
    row: {
        display: 'flex',
        '@media(max-width: 770px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    col: {
        flex: 1,
    },
    left: {
        marginRight: 25,
        '@media(max-width: 770px)': {
            margin: '0 0 40px 0',
        },
    },
    logo: {
        height: 80,
        width: 'auto',
        '@media(max-width: 900px)': {
            height: 60,
        },
        '@media(max-width: 400px)': {
            height: 'auto',
            width: '100%',
        },
    },
    right: {
        marginLeft: 40,
        flexGrow: 0,
        '@media(max-width: 770px)': {
            margin: '40px 0 0 0',
        },
    },
    languageItem: {
        display: 'inline-flex',
        marginTop: 40,
        fontSize: 16,
        fontWeight: 600,
        color: TextColor,
        alignItems: 'center',
        cursor: 'pointer',
        '@media(max-width: 770px)': {
            justifyContent: 'center',
        },
        ':hover': {
            color: Secondary,
            ':nth-child(1n) svg': {
                fill: Secondary,
            },
        },
    },
    languageIcon: {
        display: 'block',
        marginRight: 15,
        fill: TextColor,
    },
    lngTitle: {
        textTransform: 'capitalize',
        transition: 'color 0.25s ease-in-out',
    },
    dropdownActions: {
        width: 100,
        position: 'absolute',
        bottom: '100%',
        left: 0,
        margin: 0,
        padding: 7,
        fontSize: '1rem',
        fontWeight: 600,
        listStyle: 'none',
        color: TextColor,
        background: ElementBg,
        borderRadius: 10,
        boxShadow: '0px 10px 20px rgba(22, 22, 22, 0.05)',
        border: `1px solid ${StrokeColor}`,
        userSelect: 'none',
        zIndex: 1,
    },
    dropdownAction: {
        padding: '5px 10px',
        whiteSpace: 'nowrap',
        borderRadius: 5,
        ':hover': {
            background: TextArea,
        },
    },
    nav: {
        margin: 0,
        padding: 0,
    },
    navItem: {
        margin: '20px 0 0 0',
        listStyle: 'none',
        ':first-child': {
            margin: 0,
        },
        '@media(max-width: 770px)': {
            textAlign: 'center',
        },
    },
    navItemLink: {
        color: Primary,
        textDecoration: 'none',
        fontSize: 16,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        listStyle: 'none',
        userSelect: 'none',
        ':hover': {
            color: TextColor,
        },
    },
    phone: {
        fontSize: 22,
        fontWeight: 600,
        color: TextColor,
        textDecoration: 'none',
        ':hover': {
            color: Secondary,
        },
    },
    contactLink: {
        fontSize: 18,
        fontWeight: 600,
        color: TextColor,
        textDecoration: 'none',
        ':hover': {
            color: Secondary,
        },
    },
    contact: {
        margin: '0 0 15px 0',
    },
    social: {
        display: 'flex',
        margin: '25px 0 0 0',
        padding: 0,
        alignItems: 'center',
        justifyContent: 'center',
        listStyle: 'none',
    },
    socialItem: {
        margin: '0 20px',
    },
    socialItemLink: {
        display: 'block',
    },
    socialItemIcon: {
        display: 'block',
        fill: Primary,
        ':hover': {
            fill: Secondary,
        },
    },
    bottom: {
        marginTop: 50,
        justifyContent: 'space-between',
    },
    bottomMenu: {
        display: 'flex',
        margin: 0,
        padding: 0,
        '@media(max-width: 770px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    bottomMenuItem: {
        marginRight: 30,
        fontSize: 14,
        fontWeight: 600,
        listStyle: 'none',
        ':last-child': {
            marginRight: 0,
        },
        '@media(max-width: 770px)': {
            margin: 0,
        },
    },
    bottomMenuItemLink: {
        color: Secondary,
        textDecoration: 'none',
        ':hover': {
            color: Accent1,
        },
    },
    copyright: {
        fontSize: 14,
        fontWeight: 600,
        color: Gray,
        '@media(max-width: 770px)': {
            marginTop: 15,
        },
    },
});
