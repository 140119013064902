// components
import Logo from './img/logo.png';
// styles
import {css, CreateSheet} from 'aphrodite';
import {StrokeColor, TextColor} from 'src/themes/colors';

export default function LakeGarda() {
    return (
        <div className={css(Styles.block)}>
            <img
                className={css(Styles.img)}
                src={Logo}
                alt=""
            />
            <div>
                Motoragazzi srl ha partecipato al progetto AMAZING GARDA LAKE realizzato avvalendosi del finanziamento
                del Programma Operativo Complementare (POC) – POR FESR 2014-2020 della Regione del Veneto.{' '}
                <a
                    className={css(Styles.link)}
                    href="/docs/Motoragazzi_Amazing_Garda_Lake.pdf"
                    target="_blank"
                >
                    Leggi qui
                </a>
                .
            </div>
        </div>
    );
}

const Styles = CreateSheet({
    block: {
        display: 'flex',
        margin: '50px 0',
        padding: 20,
        gap: 20,
        fontSize: 18,
        fontWeight: 600,
        color: TextColor,
        alignItems: 'center',
        borderRadius: 16,
        border: `1px solid ${StrokeColor}`,
        '@media(max-width: 640px)': {
            flexDirection: 'column',
        },
    },
    img: {
        display: 'block',
        width: 110,
        flexShrink: 0,
    },
    link: {
        fontSize: 18,
        fontWeight: 700,
        color: TextColor,
        textDecoration: 'underline',
    },
});
